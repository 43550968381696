import { slideDown, slideUp, slideStop } from 'slide-anim';
import { offset } from '../helpers/offset';

interface ITogglerItem {
  $toggler: HTMLElement;
  $item: HTMLElement;
  isOpen: boolean;
  $trigger?: HTMLElement;
  $content?: HTMLElement;
  id?: string;
  $siblings?: NodeListOf<HTMLElement>;
}

export class Togglers {
  $togglers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-toggler]');

  constructor() {
    if (this.$togglers.length) this.init();
  }

  private init(): void {
    this.$togglers.forEach(($toggler: HTMLElement) => {
      const $items: NodeListOf<HTMLElement> = $toggler.querySelectorAll('[data-toggler-item]');
      let openItem: ITogglerItem | null;
      $items.forEach(($item: HTMLElement) => {
        const item: ITogglerItem = {
          $item,
          $toggler,
          $trigger: $item.querySelector('[data-toggler-trigger]'),
          $content: $item.querySelector('[data-toggler-content]'),
          id: $item.dataset.togglerItem,
          isOpen: $item.matches('.is-open'),
        };

        if (item.isOpen) openItem = item;

        item.$trigger.addEventListener('click', (e: Event) => {
          if (openItem) {
            slideStop(openItem.$content);
            openItem.$item.classList.add('slideup-start');
            slideUp(openItem.$content).then(() => {
              openItem.$item.classList.remove('slideup-start');
              openItem.$item.classList.remove('is-open');
              openItem = null;
            });
          }

          if (item !== openItem) {
            slideStop(item.$content);
            item.$item.classList.add('slidedown-start');
            slideDown(item.$content).then(() => {
              item.$item.classList.add('is-open');
              item.$item.classList.remove('slidedown-start');
              openItem = item;
              window.location.hash = item.id;
            });
          }
        });
      });
    });
    this.openHash();
  }

  private openHash(): void {
    const $items: NodeListOf<HTMLElement> = document.querySelectorAll('[data-toggler-item]');
    const headerH = (document.querySelector('[data-header]') as HTMLElement).offsetHeight;
    if ($items.length > 0 && window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      $items.forEach(($item: HTMLElement) => {
        if ($item.dataset.togglerItem === targetId) {
          const fromTop = offset($item).top - headerH;
          if (!$item.matches('.is-open')) {
            ($item.querySelector('[data-toggler-trigger]') as HTMLElement).click();
          }
          setTimeout(() => {
            window.scrollTo(0, fromTop);
          }, 1);
        }
      });
    }
  }
}
