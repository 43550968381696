import swiper from 'swiper';

const checkSequence = (slider: any) => {
  const $activeSlide = slider.slides[slider.activeIndex];
  if ($activeSlide) {
    const $sequence = $activeSlide.querySelector('[data-sequence]');
    if ($sequence) {
      if ($sequence['sequence'] && $sequence['sequence']['animation']) {
        $sequence['sequence']['animation'].play();
      } else {
        setTimeout(() => {
          checkSequence(slider);
        }, 500);
      }
    } else {
      setTimeout(() => {
        checkSequence(slider);
      }, 500);
    }
  }
};

export class Sliders {
  options: object;
  $sliders: NodeListOf<HTMLElement> = document.querySelectorAll('[data-swiper]');

  constructor() {
    this.options = {
      speed: 800,
      loop: true,
      simulateTouch: false,
      updateOnImagesReady: true,
    };
    if (this.$sliders.length) this.init();
  }

  protected init(): void {
    this.$sliders.forEach(($slider: HTMLElement) => {
      const data = { ...$slider.dataset };
      const $target: HTMLElement = $slider.querySelector('[data-swiper-target]');
      const $bullets: NodeListOf<HTMLElement> = $slider.querySelectorAll('[data-swiper-bullet]');
      const $next: HTMLElement = $slider.querySelector('[data-swiper-next]');
      const $prev: HTMLElement = $slider.querySelector('[data-swiper-prev]');
      const slider = new swiper($target, {
        ...this.options,
        spaceBetween: data['swiperSpaceBetween'] ? Number(data['swiperSpaceBetween']) : 0,
        slidesPerView: data['swiperSlidesPerView'] ? Number(data['swiperSlidesPerView']) : 1,
        autoplay: data['swiperAutoplay'] ? { delay: Number(data['swiperAutoplay']) } : false,
        preloadImages: false,
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            preloadImages: true,
          },
        },
        on: {
          transitionEnd() {
            checkSequence(this);
          },
        },
      });

      if ($bullets.length) {
        slider.on('transitionEnd', () => {
          $bullets.forEach(($b: HTMLElement, i) => {
            $b.classList.remove('is-active');
          });
          $bullets[slider.realIndex].classList.add('is-active');
        });

        slider.on('transitionStart', () => {
          $bullets.forEach(($b: HTMLElement, i) => {
            $b.classList.remove('is-active');
          });
          $bullets[slider.realIndex].classList.add('is-active');
        });

        $bullets.forEach(($bullet: HTMLElement, i) => {
          $bullet.addEventListener('click', (e: MouseEvent) => {
            $bullets.forEach(($b: HTMLElement, i) => {
              $b.classList.remove('is-active');
            });
            $bullet.classList.add('is-active');
            slider.slideToLoop(i);
          });
        });
      }

      if ($next) $next.addEventListener('click', () => slider.slideNext());
      if ($prev) $prev.addEventListener('click', () => slider.slidePrev());
    });
  }
}
