import { offset } from '../helpers/offset';

export class Tabs {
  $allTabs: NodeListOf<HTMLElement> = document.querySelectorAll('[data-tabs]');

  constructor() {
    if (this.$allTabs.length) this.init();
  }

  private init(): void {
    this.$allTabs.forEach(($tabs: HTMLElement) => {
      const $triggers: NodeListOf<HTMLElement> = $tabs.querySelectorAll('[data-tabs-trigger]');
      let $activeTrigger: HTMLElement = $tabs.querySelector('[data-tabs-trigger].is-active');
      let $activeContent: HTMLElement = $tabs.querySelector('[data-tabs-content].is-active');
      let activeId: string = $activeTrigger.dataset.tabsTrigger;
      let index = Array.prototype.indexOf.call($triggers, $activeTrigger);
      const securityWarning: HTMLElement = document.querySelector('.security_warning');
      // $tabs.classList.forEach((c) => {
      //   if (c.startsWith('active-tab')) $tabs.classList.remove(c);
      // });

      if ($tabs.classList.contains('active-tab--1')) {
        $tabs.classList.remove('active-tab--1')
      }

      if ($tabs.classList.contains('active-tab--2')) {
        $tabs.classList.remove('active-tab--2')
      }

      if ($tabs.classList.contains('active-tab--3')) {
        $tabs.classList.remove('active-tab--3')
      }

      if ($tabs.classList.contains('active-tab--4')) {
        $tabs.classList.remove('active-tab--4')
      }

      if ($tabs.classList.contains('active-tab--5')) {
        $tabs.classList.remove('active-tab--5')
      }

      $tabs.classList.add(`active-tab--${index + 1}`);

      $triggers.forEach(($targetTrigger: HTMLElement) => {
        const id: string = $targetTrigger.dataset.tabsTrigger;
        const $targetContent = $tabs.querySelector(`[data-tabs-content=${id}]`);
        
        $targetTrigger.addEventListener('click', (e: Event) => {
          $activeTrigger.classList.remove('is-active');
          $activeContent.classList.remove('is-active');
          $targetTrigger.classList.add('is-active');
          $targetContent.classList.add('is-active');
          activeId = id;
          window.location.hash = id;

          $activeTrigger = $tabs.querySelector('[data-tabs-trigger].is-active');
          $activeContent = $tabs.querySelector('[data-tabs-content].is-active');
          index = Array.prototype.indexOf.call($triggers, $activeTrigger);

          // $tabs.classList.forEach((c) => {
          //   if (c.startsWith('active-tab')) $tabs.classList.remove(c);
          // });

          if ($tabs.classList.contains('active-tab--1')) {
            $tabs.classList.remove('active-tab--1')
          }

          if ($tabs.classList.contains('active-tab--2')) {
            $tabs.classList.remove('active-tab--2')
          }

          if ($tabs.classList.contains('active-tab--3')) {
            $tabs.classList.remove('active-tab--3')
          }

          if ($tabs.classList.contains('active-tab--4')) {
            $tabs.classList.remove('active-tab--4')
          }

          if ($tabs.classList.contains('active-tab--5')) {
            $tabs.classList.remove('active-tab--5')
          }

          $tabs.classList.add(`active-tab--${index + 1}`);

          if (securityWarning) {
            if (id === 'email') securityWarning.style.display = 'block';
            else securityWarning.style.display = 'none';
          }
        });
      });
    });
    this.openHash();
  }

  private openHash(): void {
    const $tabTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-tabs-trigger]');
    const headerH = (document.querySelector('[data-header]') as HTMLElement).offsetHeight;
    if ($tabTriggers.length > 0 && window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      $tabTriggers.forEach(($trigger: HTMLElement) => {
        if ($trigger.dataset.tabsTrigger === targetId) {
          $trigger.click();
          setTimeout(() => {
            window.scrollTo(0, offset($trigger).top - headerH);
          }, 1);
        }
      });
    }
  }
}
