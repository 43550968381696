import choicesJs from 'choices.js';
import dataLayerPush from '../helpers/dataLayerPush';

interface IObject {
  [key: string]: any;
}

interface IField {
  name: string;
  type: string;
  value: string;
  $input: HTMLInputElement;
  $parent: HTMLElement;
  hasError: boolean;
  hasValue: boolean;
  isDirty: boolean;
  isFocused: boolean;
  isValid: boolean;
}

export class Former {
  public $form: HTMLFormElement;
  public $inputs: NodeListOf<HTMLInputElement>;
  public $firstFocus: HTMLInputElement = null;
  public fields: {
    [key: string]: IField;
  };
  public isDirty: boolean = false;
  public isValid: boolean = false;
  public url: string;

  constructor($form: HTMLFormElement, customSubmission?: Function) {
    this.$form = $form;
    this.$form.isDirty = false;
    this.$form.isValid = true;
    this.$form.url = $form.getAttribute('action');
    this.$form.fields = {};
    this.$form.$inputs = $form.querySelectorAll('input, select, textarea');
    this.$form.formSubmission = this.formSubmission;
    this.$form.customSubmission = customSubmission;
    this.init();
  }

  public init() {
    const $formDones: NodeListOf<HTMLInputElement> = this.$form.querySelectorAll(
      '[data-form-done]',
    );
    this.$form.setAttribute('novalidate', 'true');
    this.$form.$inputs.forEach(($input: HTMLInputElement) => {
      const $parent: HTMLElement = $input.parentElement;
      const type =
        $input.tagName.toLowerCase() === 'input'
          ? $input.getAttribute('type')
          : $input.tagName.toLowerCase();
      let value: string = $input.value;

      if (type === 'checkbox') value = $input.checked ? 'checked' : '';

      if (type === 'radio') value = $input.checked ? $input.value : '';

      let selectChoices: any;

      if (type === 'select') {
        selectChoices = new choicesJs($input, {
          searchEnabled: false,
          removeItemButton: false,
          shouldSort: false,
          itemSelectText: '',
        });

        selectChoices.containerOuter.element.addEventListener('keydown', (event: any) => {
          if (event.which === 32) {
            // event.preventDefault();
          }
        });
      }
      const name: string = $input.getAttribute('name');
      const isValid: boolean = true;
      const hasError: boolean = false;
      const isDirty: boolean = false;
      const isFocused: boolean = false;
      const hasValue: boolean = value.length > 0;

      $parent.classList[hasValue ? 'add' : 'remove']('has-value');
      $input.classList[hasValue ? 'add' : 'remove']('has-value');

      $input.addEventListener('focus', this.focusHandler(name, $input));
      $input.addEventListener('blur', this.blurHandler(name, $input));
      $input.addEventListener('change', this.changeHandler(name, $input));
      $input.addEventListener('keyup', this.changeHandler(name, $input));

      const addToFields = !$input.checked && type === 'radio' ? false : true;

      if (addToFields) {
        this.$form.fields[name] = {
          type,
          value,
          name,
          isValid,
          isDirty,
          isFocused,
          hasValue,
          hasError,
          $input,
          $parent,
        };
      }
    });

    this.$form.addEventListener('submit', (e: Event) => {
      this.$form.isValid = true;
      this.$form.isDirty = true;
      this.$form.$firstFocus = null;
      Object.keys(this.$form.fields).forEach((name: string) => {
        this.validateField(name);
      });
      if (!this.$form.isValid) {
        e.preventDefault();
        this.$form.$firstFocus.focus();
      } else {

        if (this.$form.customSubmission) {
          this.$form.customSubmission(e);
        } else {
          e.preventDefault();
          this.formSubmission(this.$form);
        }
      }
    });
    $formDones.forEach(($formDone: HTMLElement) => {
      $formDone.addEventListener('click', () => {
        this.$form.classList.remove('has-success', 'has-error');
        setTimeout(() => {
          this.$form.classList.remove('in-perspective');
        }, 1000);
      });
    });
  }

  public focusHandler(name: string, $input): EventListener {
    return (f: any) => {
      const field = this.$form.fields[name];
      // field.isFocused = true;
      // field.$input = f.target;
      // field.$parent = field.$input.parentElement;
      // field.$input.classList.add('is-focused');
      // field.$parent.classList.add('is-focused');

      if (field) field.isFocused = true;
      $input.classList.add('is-focused');
      $input.parentElement.classList.add('is-focused');
    };
  }

  public blurHandler(name: string, $input): EventListener {
    return (f: any) => {
      const field = this.$form.fields[name];
      // field.isFocused = false;
      // field.isDirty = true;
      // field.$input = f.target;
      // field.$parent = field.$input.parentElement;
      // field.$input.classList.remove('is-focused');
      // field.$parent.classList.remove('is-focused');

      if (field) field.isFocused = false;
      if (field) field.isDirty = true;
      $input.classList.remove('is-focused');
      $input.parentElement.classList.remove('is-focused');
      this.validateField(name);
    };
  }

  public changeHandler(name: string, $input): EventListener {
    // return (f: any) => {
    //   const field = this.$form.fields[name];
    //   field.$input = f.target;
    //   field.$parent = field.$input.parentElement;
    //   if (field.type === 'radio') {
    //     this.$form.querySelectorAll(`input[name="${name}"]`).forEach(($i: any) => {
    //       $i.classList.remove('has-value');
    //       $i.parentElement.classList.remove('has-value');
    //     });
    //     field.value = field.$input.checked ? field.$input.value : '';
    //   } else {
    //     field.value = field.$input.value;
    //   }
    //   field.hasValue = field.value.length > 0;
    //   if (field.isDirty) {
    //     this.validateField(name);
    //   }
    //   field.$input.classList[field.hasValue ? 'add' : 'remove']('has-value');
    //   field.$parent.classList[field.hasValue ? 'add' : 'remove']('has-value');
    //   if (
    //     field.type === 'file' &&
    //     field.$parent.querySelector('[data-file-name]') &&
    //     typeof field.$input.files[0] !== 'undefined'
    //   ) {
    //     field.$parent.querySelector('[data-file-name]').innerText = field.$input.files[0].name;
    //   }
    // };
    return (f: any) => {
      const field = this.$form.fields[name];
      if (field.type === 'radio') {
        this.$form.querySelectorAll(`input[name="${name}"]`).forEach(($i: any) => {
          $i.classList.remove('has-value');
          $i.parentElement.classList.remove('has-value');
        });
        field.value = $input.checked ? $input.value : '';
      } else {
        field.value = $input.value;
      }
      field.hasValue = field.value.length > 0;
      if (field.isDirty) this.validateField(name);

      $input.classList[field.hasValue ? 'add' : 'remove']('has-value');
      $input.parentElement.classList[field.hasValue ? 'add' : 'remove']('has-value');


      if (
        field.type === 'file' &&
        $input.parentElement.querySelector('[data-file-name]') &&
        typeof $input.files[0] !== 'undefined'
      ) {
        $input.parentElement.querySelector('[data-file-name]').innerText = $input.files[0].name;
      }
    };
  }

  public formSubmission($form: HTMLFormElement) {
    const formData: FormData = new FormData();
    let valid_form = true;
    let honeypot = false;

    Object.keys($form.fields).forEach((field: any) => {

      if($form.fields[field]['$input'].name === 'hidden'){
        if($form.fields[field]['$input'].value != ''){
          honeypot = true;
          valid_form = false;
        }
      }

      if ($form.fields[field]['$input'].type === 'file') {

        const file_size = $form.fields[field]['$input'].files[0].size;
        const file_size_kb = file_size / Math.pow(1024,1);

        if(file_size_kb > 3072){
          document.querySelector('.form__item--file-size').classList.add('has-error');
          valid_form = false;
        }
        else{
          document.querySelector('.form__item--file-size').classList.remove('has-error');
        }

        formData.append(field, $form.fields[field]['$input'].files[0]);
      } else if ($form.fields[field]['$input'].type === 'checkbox') {
        const isChecked = $form.fields[field]['$input'].checked;
        if (isChecked) {
          formData.append(field, 'true');
        } else {
          formData.append(field, 'false');
        }
      } else {
        formData.append(field, $form.fields[field]['$input'].value);
      }
    });

    if(honeypot){
      $form.classList.add(`has-success`);
      $form.reset();
    }

    if(valid_form){
      const formSubmitBtn = $form.querySelector('.form__submit');
      formSubmitBtn.classList.add('btn--disabled');

      $form.classList.add('in-perspective');
      fetch($form.url, {
        body: formData,
        method: 'post',
      })
      .then(r => r.text())
      .then((response: any) => {
        $form.classList.add(`has-${response}`);
        formSubmitBtn.classList.remove('btn--disabled');

        if (response == 'success'){
            $form.reset();
            const file_name: HTMLElement = $form.querySelector('[data-file-name]');
            if(file_name){
              const reset_value = file_name.dataset.fileName;
              file_name.innerHTML = reset_value;
            }
        }

        if ($form.dataset.form && $form.dataset.form === 'subscribe') {
          dataLayerPush({ event: 'newsletterSubscription' });
        }
      })
      .catch((error: any) => {
        formSubmitBtn.classList.remove('btn--disabled');
        $form.classList.add('has-error');
      });
    }
  }

  public validateField(name: string) {
    this.$form.fields[name].isDirty = true;

    const field: IField = this.$form.fields[name];
    const type: string = field['type'];
    const $parent: HTMLElement = field['$parent'];
    const $input: HTMLInputElement = field['$input'];

    let value: string = $input.value;

    if (type === 'checkbox') {
      value = $input.checked ? 'checked' : '';
    }

    if (type === 'radio') {
      value = $input.checked ? $input.value : '';
    }

    let isValid: boolean = true;
    let hasError: boolean = false;
    const hasValue: boolean = value.length > 0;

    //// check for required
    if ($input.hasAttribute('required')) {
      isValid = hasValue ? isValid : false;
    }

    //// check for email
    if (type === 'email') {
      const emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      isValid = emailRegex.test($input.value) ? isValid : false;
    }

    //// check for tel
    if (type === 'tel') {
      const telRegex: RegExp = /[()+0-9\s]+$/;
      isValid = telRegex.test($input.value) && value.length > 7 ? isValid : false;
    }

    // //// check for number
    // if (type === 'number') {
    //   isValid = Number(value) > 0 ? isValid : false;
    // }

    //// check for minlength
    if ($input.hasAttribute('minlength')) {
      const minlength: number = parseInt($input.getAttribute('minlength'), 10);
      isValid = $input.value.length >= minlength ? isValid : false;
    }

    //// check for min number
    if ($input.hasAttribute('min')) {
      const min: number = parseInt($input.getAttribute('min'), 10);
      isValid = Number($input.value) >= min ? isValid : false;
    }

    //// check for pattern
    if ($input.hasAttribute('pattern')) {
      const regex: RegExp = new RegExp($input.getAttribute('pattern'));
      isValid = regex.test($input.value) ? isValid : false;
    }

    //// check for file type
    if ($input.hasAttribute('accept')) {
      let match = false;
      if (typeof $input.files[0] !== 'undefined') {
        const acceptedTypes: string[] = $input
          .getAttribute('accept')
          .trim()
          .split(',');
        acceptedTypes.forEach((acceptedType: string) => {
          match = $input.files[0].type.includes(acceptedType.replace('.', '')) ? true : match;
        });
        isValid = match;
      }
    }

    //// check for match
    if ($input.hasAttribute('data-match')) {
      const match: string = $input.getAttribute('data-match');
      isValid =
        $input.value === this.$form.fields[match].value &&
        this.$form.fields[match].isDirty &&
        !this.$form.fields[match].hasError
          ? isValid
          : false;
    }

    hasError = !isValid;
    this.$form.isValid = hasError ? false : this.$form.isValid;
    this.$form.$firstFocus = hasError && !this.$form.$firstFocus ? $input : this.$form.$firstFocus;

    if($input.name != 'wizard-amount-input' && $input.name != 'wizard-term'){
      $parent.classList[hasError ? 'add' : 'remove']('has-error');
      $input.classList[hasError ? 'add' : 'remove']('has-error');
    }
  }
}
