import { slideDown, slideUp, slideStop } from 'slide-anim';

export class Sitemap {
  $sitemap: HTMLElement = document.querySelector('[data-sitemap]');
  $$items: NodeListOf<HTMLElement> = document.querySelectorAll('[data-sitemap-item]');
  $expand: HTMLElement = document.querySelector('[data-sitemap-expand]');
  $contract: HTMLElement = document.querySelector('[data-sitemap-contract]');

  constructor() {
    if (this.$sitemap) this.init();
  }

  public init(): void {
    this.$expand.addEventListener('click', () => this.expand());
    this.$contract.addEventListener('click', () => this.contract());
  }

  public contract() {
    this.$expand.classList.add('is-active');
    this.$contract.classList.remove('is-active');
    [].forEach.call(this.$$items, ($item: HTMLElement) => {
      slideStop($item);
      $item.classList.add('slideup-start');
      slideUp($item).then(() => {
        $item.classList.remove('slideup-start');
        $item.classList.remove('is-active');
      });
    });
  }

  public expand() {
    this.$expand.classList.remove('is-active');
    this.$contract.classList.add('is-active');
    [].forEach.call(this.$$items, ($item: HTMLElement) => {
      slideStop($item);
      $item.classList.add('slidedown-start');
      slideDown($item).then(() => {
        $item.classList.add('is-active');
        $item.classList.remove('slidedown-start');
      });
    });
  }
}
