export class Toast {
  $toast: HTMLElement = document.querySelector('[data-toast]');
  $toastClose: HTMLElement = document.querySelector('[data-toast-close]');
  isActive: boolean = false;
  delay: number;

  constructor() {
    if (this.$toast) this.init();
  }

  public init() {
    this.delay = Number(this.$toast.dataset['toast']);
    setTimeout(() => {
      this.$toast.classList.add('is-active');
    }, this.delay);
    this.$toastClose.addEventListener('click', (e: MouseEvent) => {
      this.$toast.classList.remove('is-active');
    });
  }
}
