import * as jsCookie from 'js-cookie';
import animejs from 'animejs';

export class Cookiebar {
  hasCookie: boolean = Boolean(jsCookie.get('cookiebar')) || false;
  $bar: HTMLElement = document.querySelector('[data-cookiebar]');
  appPadding: number =
    Number(window.getComputedStyle(document.body).paddingTop.replace('px', '')) || 0;
  barHeight: number;
  $accept: HTMLElement = document.querySelector('[data-cookiebar-accept]');
  endpoint: string;

  constructor() {
    if (this.$bar) this.init();
  }

  private init(): void {
    this.barHeight = this.$bar.clientHeight;
    this.endpoint = this.$bar.dataset.cookiebar;
    this.$bar.classList[this.hasCookie ? 'remove' : 'add']('is-active');
    if (this.hasCookie) {
      this.$bar.parentNode.removeChild(this.$bar);
    } else {
      animejs({
        targets: 'body',
        duration: 350,
        translateY: this.barHeight,
        easing: 'easeOutQuad',
      });
    }
    if (this.$accept) {
      this.$accept.onclick = () => {
        fetch(this.endpoint)
          .then((r: Response) => r.text())
          .then((response: any) => {
            if (response === 'success') {
              // jsCookie.set('cookiebar', true, { expires: 999 });
              this.hasCookie = true;
              this.$bar.classList.remove('is-active');
              animejs({
                targets: 'body',
                duration: 350,
                translateY: 0,
                easing: 'easeOutQuad',
                complete: () => {
                  document.body.style.transform = '';
                  document.body.style['msTransform'] = '';
                  document.body.style['WebkitTransform'] = '';
                  this.$bar.parentNode.removeChild(this.$bar);
                },
              });
            } else {
              console.log('error');
            }
          })
          .catch(error => console.log(error));
      };
    }
  }
}
