// import anime from 'animejs/lib/anime.es.js';
// import anime from 'animejs/lib/anime.es.js';
import animejs from 'animejs';

export class Sequence {
  $sequence: HTMLElement = document.querySelector('[data-sequence]');
  $placeholderImage: HTMLElement;
  $canvas: HTMLCanvasElement;
  canvasWidth: number;
  canvasHeight: number;
  imageWidth: number;
  imageHeight: number;
  count: number;
  interval: number;
  fps: number;
  duration: number;
  step: number;
  repeat: number = 1;
  $canvasImage: HTMLImageElement;
  ctx: CanvasRenderingContext2D;
  currentFrame: number = 0;
  hasAnimated: boolean = false;
  isLoaded: boolean = false;
  animation;

  constructor($el: HTMLElement) {
    this.$sequence = $el;
    if ($el) this.init();
  }

  public init(): void {
    this.$canvas = this.$sequence.querySelector('[data-sequence-canvas]');
    this.$placeholderImage = this.$sequence.querySelector('[data-sequence-placeholder]');
    this.count = Number(this.$sequence.dataset.sequenceFrames);
    this.fps = Number(this.$sequence.dataset.sequenceFps);
    this.canvasWidth = this.$placeholderImage.clientWidth;
    this.canvasHeight = this.$placeholderImage.clientHeight;
    this.$canvas.setAttribute('width', this.canvasWidth.toString());
    this.$canvas.setAttribute('height', this.canvasHeight.toString());
    this.step = 1000 / this.fps;
    this.repeat = Number(this.$sequence.dataset['sequenceRepeat']) || 1;
    this.duration = this.step * this.count;
    this.ctx = this.$canvas.getContext('2d');
    this.$canvasImage = new Image();
    this.$canvasImage.src = this.$sequence.dataset.sequence;
    this.$canvasImage.onload = (e: any) => {
      this.isLoaded = true;
      this.imageWidth = this.$canvasImage.width / this.count;
      this.imageHeight = this.$canvasImage.height;
      this.$placeholderImage.classList.add('is-hidden');
      const o = { prop1: 0 };
      this.draw();
      this.animation = animejs({
        targets: o,
        duration: this.duration,
        autoplay: false,
        prop1: this.count - 1,
        round: 1,
        loop: this.repeat,
        easing: `steps(${this.count - 1})`,
        update: (a: any) => {
          const currentFrame = a.animations[0].currentValue;
          if (currentFrame === this.count - 1) this.hasAnimated = true;
          // if (!this.hasAnimated && currentFrame !== this.currentFrame) {
          if (currentFrame !== this.currentFrame) {
            this.currentFrame = currentFrame;
            this.draw();
          }
        },
      });
    };
  }

  public draw() {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.ctx.drawImage(
      this.$canvasImage,
      this.imageWidth * this.currentFrame,
      0,
      this.imageWidth,
      this.imageHeight,
      0,
      0,
      this.canvasWidth,
      this.canvasHeight,
    );
  }

  public play() {
    if (this.isLoaded) {
      this.animation.play();
    } else {
      setTimeout(() => {
        this.play();
      }, 100);
    }
  }
}
