import * as Masonry from 'masonry-layout';
import { slideDown, slideUp, slideStop } from 'slide-anim';

export class Reviews {
  msnry: Masonry;
  $reviewsMasonry: HTMLElement = document.querySelector('[data-reviews-masonry]');
  $reviewsReveal: HTMLElement = document.querySelector('[data-reviews-reveal]');
  $reviewsItems: NodeListOf<HTMLElement> = document.querySelectorAll('[data-reviews-item]');
  $reviewsItemsHidden: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-reviews-item="hidden"]',
  );
  reviewsItemsLeft: number;
  revealOptions: object = {};

  constructor() {
    if (this.$reviewsMasonry) {
      if (screen.width > 768) {
        this.msnry = new Masonry(this.$reviewsMasonry, {
          itemSelector: '[data-reviews-item="visible"]',
          percentPosition: true,
        });
      }

      this.$reviewsReveal.addEventListener('click', (e: Event) => {
        this.reviewsItemsLeft = this.$reviewsItemsHidden.length;
        if (this.reviewsItemsLeft) {
          let load: number = 4;
          let delay: number = 0;
          let i: number = 0;
          load = this.reviewsItemsLeft < load ? this.reviewsItemsLeft : load;
          Array(load)
            .fill(i)
            .forEach(() => {
              setTimeout(() => {
                this.$reviewsItemsHidden[i].classList.remove('is-hidden');
                this.$reviewsItemsHidden[i].dataset.reviewsItem = 'visible';
                if (screen.width > 768) {
                  this.msnry = new Masonry(this.$reviewsMasonry, {
                    itemSelector: '[data-reviews-item="visible"]',
                    percentPosition: true,
                  });
                }
                if (i === load - 1) {
                  this.$reviewsItemsHidden = document.querySelectorAll(
                    '[data-reviews-item="hidden"]',
                  );
                  this.reviewsItemsLeft = this.$reviewsItemsHidden.length;
                }
                if (!this.reviewsItemsLeft) this.$reviewsReveal.classList.add('is-hidden');
                i += 1;
              }, delay);
              delay += 150;
            });
        }
      });
    }
  }
}
