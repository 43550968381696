import * as latinize from 'latinize';

export class Assoc {
  query: string = '';
  $assoc: HTMLElement = document.querySelector('[data-assoc]');
  $input: HTMLInputElement = document.querySelector('[data-assoc-input]');
  $noResults: HTMLElement = document.querySelector('[data-assoc-no-results]');
  $pagination: HTMLElement = document.querySelector('[data-assoc-pagination]');
  $assocItems: NodeListOf<HTMLElement> = document.querySelectorAll('[data-assoc-item]');

  constructor() {
    this.inputHandler = this.inputHandler.bind(this);
    if (this.$assoc) this.init();
  }

  public init(): void {
    this.$assocItems.forEach(($item: any) => {
      $item.querySelectorAll('[data-assoc-value]').forEach(($value: any) => {
        $item['text'] =
          $item['text'] +
          latinize($value.innerText)
            .toLowerCase()
            .trim();
      });
    });
    //this.$input.addEventListener('input', this.inputHandler());
  }

  public inputHandler(): EventListener {
    return () => {
      let found: number = 0;
      this.query = latinize(this.$input.value).toLowerCase();
      this.$assocItems.forEach(($item: any) => {
        const inString = new RegExp(this.query).test($item['text']);
        $item.classList[inString ? 'remove' : 'add']('is-hidden');
        found = inString ? found + 1 : found;
      });
      this.$noResults.classList[found > 0 ? 'remove' : 'add']('is-visible');
      this.$pagination.classList[found > 0 ? 'remove' : 'add']('is-hidden');
    };
  }
}
