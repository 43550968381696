export class LinkedinToggle {
  $checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll('[data-linkedin-agree]');
  $buttons: NodeListOf<HTMLElement> = document.querySelectorAll('[data-linkedin-btn]');
  isChecked: boolean = false;

  constructor() {
    if (this.$checkboxes.length) this.init();
  }

  public init() {
    this.isChecked = this.$checkboxes[0].checked;
    this.setState(this.isChecked);
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.onchange = () => {
        this.isChecked = !this.isChecked;
        this.setState(this.isChecked);
      };
    });
  }

  public setState(state: boolean) {
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.checked = state;
    });
    this.$buttons.forEach(($button) => {
      $button.classList[state ? 'remove' : 'add']('is-disabled');
    });
  }
}
