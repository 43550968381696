export class Offcanvas {
  $offcanvases: NodeListOf<HTMLElement> = document.querySelectorAll('[data-offcanvas]');
  $offcanvasTriggers: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-offcanvas-trigger]',
  );
  $offcanvasClosers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-offcanvas-close]');
  $app: HTMLElement = document.body;
  activeType: string = null;

  constructor() {
    if (this.$offcanvasTriggers.length > 0) this.init();
  }

  public init() {
    this.$offcanvasTriggers.forEach(($trigger) => {
      $trigger.addEventListener('click', (e: MouseEvent) => {
        const target = $trigger.getAttribute('data-offcanvas-trigger');
        if (this.activeType === target) {
          this.close();
        } else {
          this.open(target);
        }
      });
    });

    this.$app.addEventListener('keydown', (e: KeyboardEvent) => {
      if (Number(e.keyCode) === 27) {
        this.close();
      }
    });

    this.$offcanvasClosers.forEach(($closer) => {
      $closer.addEventListener('click', (e) => {
        this.close();
      });
    });

    this.checkHash();
  }

  public open(target: string): void {
    this.activeType = target;
    this.$app.classList.add('offcanvas-open');
    window.location.hash = target;
    this.$offcanvases.forEach(($container) => {
      const type = $container.getAttribute('data-offcanvas');
      this.$app.classList[type === target ? 'add' : 'remove'](`${type}-open`);
      $container.classList[type === target ? 'add' : 'remove']('is-open');
    });
  }

  public close(): void {
    this.activeType = null;
    this.$app.classList.remove('offcanvas-open');
    this.$offcanvases.forEach(($container) => {
      const type = $container.getAttribute('data-offcanvas');
      this.$app.classList.remove(`${type}-open`);
      $container.classList.remove('is-open');
    });
  }

  public checkHash(): void {
    if (window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      this.$offcanvases.forEach(($offcanvas) => {
        if ($offcanvas.dataset.offcanvas === targetId) this.open(targetId);
      });
    }
  }
}
