import "../scss/index.scss";
import cssVarsPonyfill from "css-vars-ponyfill";
import * as Stickyfill from "stickyfilljs";
// import * as elementClosest from 'element-closest';
// import { elementClosest } from 'element-closest';
import * as scrollMonitor from "scrollmonitor";
import urijs from "urijs";

import { Cookiebar } from "./utilities/cookiebar";
import { Former } from "./utilities/former";
import { Offcanvas } from "./utilities/offcanvas";
import { Sliders } from "./utilities/sliders";
import { Tabs } from "./utilities/tabs";
import { Togglers } from "./utilities/togglers";
import { TrackScroll } from "./utilities/track-scroll";

import { Assoc } from "./components/assoc";
// import { PartnersMap } from './components/partners-map';
import { Proffer } from "./components/proffer";
import { Reviews } from "./components/reviews";
import { Faq } from "./components/faq";
import { LinkedinToggle } from "./components/linkedinToggle";
import { Maps } from "./components/maps";
import { Sequence } from "./components/sequence";
import { Sitemap } from "./components/sitemap";
import { Toast } from "./components/toast";
import { Wizard } from "./components/wizard";
import StickyToParent from "./components/stickyToParent";

(() => {
  if (typeof (NodeList as any).prototype.forEach !== "function") {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as any)["msMatchesSelector"] ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: any) {
      var el: any = this;
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  return false;
})();

window.addEventListener("load", () => {
  if (document.querySelector("[data-sticky]")) {
    Stickyfill.addOne(document.querySelector("[data-sticky]") as HTMLElement);
  }
  cssVarsPonyfill();

  document.querySelectorAll("form").forEach(($form: HTMLFormElement) => {
    if (!$form.matches(".custom-submit")) new Former($form);

    if ($form.matches("[data-user-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });
        // const endpoint = urijs($form.url).query({ ...query });

        try {
          $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
          $form
            .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");
        } catch({message}) {console.log('error ' + message)}
        
        fetch($form.url, {
          method: "post",
          body: JSON.stringify({...query})
        })
          .then((r: Response) => r.text())
          .then((response: any) => {
            const jsonResponse = JSON.parse(response);
            if (jsonResponse.redirect_type) {
              const username: HTMLFormElement = $form.querySelector(
                'input[name="email"]'
              );
              const password: HTMLFormElement = $form.querySelector(
                'input[name="password"]'
              );
              if (jsonResponse.redirect_type === "client") {
                const redirectForm: HTMLFormElement = document.querySelector(
                  ".redirect-form--client"
                );
                if (redirectForm) {
                  const redirectUsername: HTMLFormElement = redirectForm.querySelector(
                    'input[name="j_username"]'
                  );
                  const redirectPassword: HTMLFormElement = redirectForm.querySelector(
                    'input[name="j_password"]'
                  );
                  if (redirectUsername && redirectPassword) {
                    redirectUsername.value = username.value;
                    redirectPassword.value = password.value;
                    redirectForm.submit();
                  }
                }
              } else if (jsonResponse.redirect_type === "partner") {
                if (jsonResponse.portal_version) {
                  if (jsonResponse.portal_version === "N") {
                    location.href = jsonResponse.redirect_url;
                  }
                } else {
                  const redirectForm: HTMLFormElement = document.querySelector(
                    ".redirect-form--partner"
                  );
                  if (redirectForm) {
                    const redirectUsername: HTMLFormElement = redirectForm.querySelector(
                      'input[name="user"]'
                    );
                    const redirectPassword: HTMLFormElement = redirectForm.querySelector(
                      'input[name="password"]'
                    );
                    if (redirectUsername && redirectPassword) {
                      redirectUsername.value = username.value;
                      redirectPassword.value = password.value;
                      redirectForm.submit();
                    }
                  }
                }
              }
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            try {
              $form.classList.add("has-error");
              $form
                .querySelector(".done__text--main-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            } catch({message}) {console.log('error ' + message)}
          });
      });
    }

    if ($form.matches("[data-smartid-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });
        const endpoint = urijs($form.url).query({ ...query });

        $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
        $form
          .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");

        fetch(endpoint, {
          method: "post",
        })
          .then((r: Response) => r.text())
          .then((response) => {
            const jsonResponse = JSON.parse(response);
            formSubmitBtn.classList.remove("btn--disabled");

            if (jsonResponse.status == "success") {
              const controlCode: HTMLElement = $form.querySelector(
                ".control_code"
              );
              const counter: HTMLElement = $form.querySelector(".counter");
              var counterValue: any = 100;

              const counterInterval = setInterval(() => {
                counterValue--;
                counter.innerHTML = counterValue;
                if (counterValue <= 0) {
                  clearInterval(counterInterval);
                }
              }, 1000);

              controlCode.innerHTML = jsonResponse.control_code;
              $form.classList.add("has-success");

              setTimeout(() => {
                getSmartIDStatus($form, counterInterval, jsonResponse.token);
              }, 5000);
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            $form.classList.add("has-error");
            $form
              .querySelector(".done__text--main-error")
              .classList.remove("done__text--hide");
            formSubmitBtn.classList.remove("btn--disabled");
          });
      });
    }

    if ($form.matches("[data-signature-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });
        const endpoint = urijs($form.url).query({ ...query });

        $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
        $form
          .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");

        fetch(endpoint, {
          method: "post",
        })
          .then((r: Response) => r.text())
          .then((response) => {
            const jsonResponse = JSON.parse(response);
            formSubmitBtn.classList.remove("btn--disabled");

            if (jsonResponse.status == "success") {
              const controlCode: HTMLElement = $form.querySelector(
                ".control_code"
              );
              const counter: HTMLElement = $form.querySelector(".counter");
              var counterValue: any = 100;

              const counterInterval = setInterval(() => {
                counterValue--;
                counter.innerHTML = counterValue;
                if (counterValue <= 0) {
                  clearInterval(counterInterval);
                }
              }, 1000);

              controlCode.innerHTML = jsonResponse.control_code;
              $form.classList.add("has-success");

              setTimeout(() => {
                getSignatureStatus($form, counterInterval, jsonResponse.token);
              }, 5000);
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            $form.classList.add("has-error");
            $form
              .querySelector(".done__text--main-error")
              .classList.remove("done__text--hide");
            formSubmitBtn.classList.remove("btn--disabled");
          });
      });
    }

    if ($form.matches("[data-reminder-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });
        const endpoint = urijs($form.url).query({ ...query });

        $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
        $form
          .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");

        fetch(endpoint, {
          method: "post",
        })
          .then((r: Response) => r.text())
          .then((response: any) => {
            const jsonResponse = JSON.parse(response);
            formSubmitBtn.classList.remove("btn--disabled");

            if (jsonResponse.status == "success") {
              $form.classList.add("has-success");
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            $form.classList.add("has-error");
            $form
              .querySelector(".done__text--main-error")
              .classList.remove("done__text--hide");
            formSubmitBtn.classList.remove("btn--disabled");
          });
      });
    }

    if ($form.matches("[data-change-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });
        const endpoint = urijs($form.url).query({ ...query });

        $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
        $form
          .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");

        fetch(endpoint, {
          method: "post",
        })
          .then((r: Response) => r.text())
          .then((response: any) => {
            const jsonResponse = JSON.parse(response);
            formSubmitBtn.classList.remove("btn--disabled");

            if (jsonResponse.status === "success") {
              $form.classList.add("has-success");
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            $form.classList.add("has-error");
            $form
              .querySelector(".done__text--main-error")
              .classList.remove("done__text--hide");
            formSubmitBtn.classList.remove("btn--disabled");
          });
      });
    }

    if ($form.matches("[data-registration-form]")) {
      new Former($form as HTMLFormElement, (e: Event) => {
        e.preventDefault();
        const formSubmitBtn = $form.querySelector(".form__submit");

        if (formSubmitBtn) {
          formSubmitBtn.classList.add("btn--disabled");
        }

        const query = {};
        Object.keys($form.fields).forEach((field) => {
          query[field] = $form.fields[field]["$input"].value;
        });

        const endpoint = urijs($form.url).query({ ...query });

        $form
          .querySelector(".done__text--main-error")
          .classList.add("done__text--hide");
        $form
          .querySelector(".done__text--api-error")
          .classList.add("done__text--hide");

        fetch(endpoint, {
          method: "post",
        })
          .then((r: Response) => r.text())
          .then((response: any) => {
            const jsonResponse = JSON.parse(response);
            formSubmitBtn.classList.remove("btn--disabled");

            if (jsonResponse.status === "success") {
              $form.classList.add("has-success");
            } else {
              $form.classList.add("has-error");
              $form.querySelector(".done__text--api-error").innerHTML =
                jsonResponse.message;
              $form
                .querySelector(".done__text--api-error")
                .classList.remove("done__text--hide");
              formSubmitBtn.classList.remove("btn--disabled");
            }
          })
          .catch((error: any) => {
            $form.classList.add("has-error");
            $form
              .querySelector(".done__text--main-error")
              .classList.remove("done__text--hide");
            formSubmitBtn.classList.remove("btn--disabled");
          });
      });
    }
  });

  if (window.innerWidth > 768) {
    document
      .querySelectorAll("[data-sequence]")
      .forEach(($sequence: HTMLElement) => {
        $sequence["sequence"] = new Sequence($sequence as HTMLElement);
        if (!$sequence.closest("[data-swiper]")) {
          const watcher = scrollMonitor.create($sequence);
          if (watcher.isFullyInViewport) {
            setTimeout(() => {
              $sequence["sequence"].play();
            }, 1000);
          }
          watcher.fullyEnterViewport(() => {
            if ($sequence["sequence"].animation) $sequence["sequence"].play();
          });
        }
      });
  }

  new Cookiebar();
  new Offcanvas();
  new Sliders();
  new Tabs();
  new Togglers();
  new TrackScroll();

  new Assoc();
  new Faq();
  new LinkedinToggle();
  new Maps();
  new Proffer();
  new Reviews();
  // new PartnersMap();
  new Sitemap();
  new Toast();

  if (document.querySelector("[data-wizard]")) {
    // document.querySelectorAll('[data-wizard]').forEach($wizard => new Wizard({ target: $wizard }));
    document
      .querySelectorAll("[data-wizard]")
      .forEach(($wizard: HTMLElement) => new Wizard($wizard));
  }

  StickyToParent.init();

  setTimeout(() => {
    document.body.classList.remove("preload");
  }, 1);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  document.body.classList.add(isIOS ? "is-ios" : "no-ios");
});

function getSmartIDStatus($form, counterInterval, token) {
  fetch(`/endpoint/login/smartid/getstatus?token=${token}`, {
    method: "GET",
  })
    .then((r: Response) => r.text())
    .then((response) => {
      const jsonResponse = JSON.parse(response);
      if (jsonResponse.status == "success") {
        if (jsonResponse.api_status.toLowerCase() == "waiting") {
          setTimeout(() => {
            getSmartIDStatus($form, counterInterval, token);
          }, 3000);
        } else if (jsonResponse.api_status.toLowerCase() == "ok") {
          location.href = `https://e.mokilizingas.lt/client/lt?login_token=${jsonResponse.token}`;
        }
      } else {
        clearInterval(counterInterval);
        $form.classList.remove("has-success");
        $form.classList.add("has-error");
        $form.querySelector(".done__text--api-error").innerHTML =
          jsonResponse.message;
        $form
          .querySelector(".done__text--api-error")
          .classList.remove("done__text--hide");
      }
    })
    .catch((error) => {
      clearInterval(counterInterval);
      $form.classList.add("has-error");
      $form
        .querySelector(".done__text--main-error")
        .classList.remove("done__text--hide");
    });
}

function getSignatureStatus($form, counterInterval, token) {
  $form
    .querySelector(".done__text--main-error")
    .classList.add("done__text--hide");
  $form
    .querySelector(".done__text--api-error")
    .classList.add("done__text--hide");

  fetch(`/endpoint/login/signature/getstatus?token=${token}`, {
    method: "GET",
  })
    .then((r: Response) => r.text())
    .then((response) => {
      const jsonResponse = JSON.parse(response);
      if (jsonResponse.status == "success") {
        if (jsonResponse.api_status.toLowerCase() == "waiting") {
          setTimeout(() => {
            getSignatureStatus($form, counterInterval, token);
          }, 3000);
        } else if (jsonResponse.api_status.toLowerCase() == "ok") {
          clearInterval(counterInterval);
          location.href = `https://e.mokilizingas.lt/client/lt?login_token=${jsonResponse.token}`;
        }
      } else {
        clearInterval(counterInterval);
        $form.classList.remove("has-success");
        $form.classList.add("has-error");
        $form.querySelector(".done__text--api-error").innerHTML =
          jsonResponse.message;
        $form
          .querySelector(".done__text--api-error")
          .classList.remove("done__text--hide");
      }
    })
    .catch((error) => {
      clearInterval(counterInterval);
      $form.classList.add("has-error");
      $form
        .querySelector(".done__text--main-error")
        .classList.remove("done__text--hide");
    });
}
