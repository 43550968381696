// import { throttle } from '../utilities/throttle';

export class TrackScroll {
  isSwping: any = false;
  touchStarted: any = false;
  pos: number = window.scrollY;
  speed: number = 0;
  lastPos: number = null;
  headerUp: boolean = window.scrollY > 1;
  headerUpTreshold: number = 50;
  goingUp: boolean = false;
  headerNarrow: boolean = false;
  scrollTimer: any;
  startY: number = null;

  constructor() {
    this.init();
  }

  public init(): void {
    window.addEventListener('scroll', (e) => {
      if (this.lastPos !== null) this.speed = this.pos - this.lastPos;
      this.lastPos = this.pos;
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.lastPos = null;
        this.speed = 0;
      }, 50);
      this.pos = window.scrollY;
      if (this.pos > this.headerUpTreshold) {
        if (this.headerUp && this.speed < 0) {
          this.headerUp = false;
          window.requestAnimationFrame(() => {
            document.body.classList.remove('header-up');
          });
        } else if (!this.headerUp && this.speed > 0) {
          this.headerUp = true;
          window.requestAnimationFrame(() => {
            document.body.classList.add('header-up');
          });
        }
      }
    });
  }
}
