import * as noUiSlider from 'noUiSlider';
import urijs from 'urijs';
import { Former } from '../utilities/former';
import dataLayerPush from '../helpers/dataLayerPush';
import choicesJs from 'choices.js';

export class Wizard {
  initialData: any;

  dropdownDom: HTMLElement;
  formDom: HTMLElement;

  amountSliderDom: HTMLElement;
  amountSlider: any;
  amountRangeStep: number = 5;
  amountMinDom: HTMLElement;
  amountMaxDom: HTMLElement;
  amountInput: HTMLFormElement;

  termsSliderDom: HTMLElement;
  termsSlider: any;
  termsRangeStep: number = 3;
  termsMinDom: HTMLElement;
  termsMaxDom: HTMLElement;
  termsInput: HTMLFormElement;

  explainDom: HTMLElement;
  explainTermDom: HTMLElement;
  explainMinAmountDom: HTMLElement;
  explainMaxAmountDom: HTMLElement;

  activeLoan: any;
  activeTerm: any;
  activeAmount: any;

  euroSuffix: string = '\xa0\u20AC';
  monthSuffix: string = '\xa0mėn';

  exampleAmount: HTMLElement;
  exampleTerm: HTMLElement;
  exampleYearly: HTMLElement;
  exampleFee: HTMLElement;
  exampleMonthlyFee: HTMLElement
  exampleBvkkmn: HTMLElement;
  exampleTotal: HTMLElement;
  exampleMonthly: HTMLElement;
  exampleMonthly2: HTMLElement;

  constructor(selector: HTMLElement) {
    if (selector) {
      this.init(selector);
    }
  }

  private init(selector: HTMLElement) {
    this.formDom = selector.querySelector('[data-wizard-form]');

    this.initialData = (window as any).calc_info;
    this.dropdownDom = selector.querySelector('[data-wizard-select]');

    this.amountSliderDom = selector.querySelector('[data-wizard-range]');
    this.termsSliderDom = selector.querySelector('[data-wizard-term-range]');

    this.amountMinDom = selector.querySelector('.form__label--wizard-amount-min');
    this.amountMaxDom = selector.querySelector('.form__label--wizard-amount-max');

    this.termsMinDom = selector.querySelector('.form__label--wizard-term-min');
    this.termsMaxDom = selector.querySelector('.form__label--wizard-term-max');

    this.termsInput = selector.querySelector('.form__wizard-count--term');
    this.amountInput = selector.querySelector('.form__wizard-count--amount');

    this.explainDom = selector.querySelector('[data-explain]');
    this.explainTermDom = selector.querySelector('[data-explain-term]');
    this.explainMinAmountDom = selector.querySelector('[data-explain-min-amount]');
    this.explainMaxAmountDom = selector.querySelector('[data-explain-max-amount]');

    this.exampleAmount = selector.querySelector('[data-wizard-example-amount]');
    this.exampleTerm = selector.querySelector('[data-wizard-example-term]');
    this.exampleYearly = selector.querySelector('[data-wizard-example-yearly]');
    this.exampleFee = selector.querySelector('[data-wizard-example-fee]');
    this.exampleMonthlyFee = selector.querySelector('[data-tariff-fee-monthly-perc]');
    this.exampleBvkkmn = selector.querySelector('[data-wizard-example-bvkkmn]');
    this.exampleTotal = selector.querySelector('[data-wizard-example-total]');
    this.exampleMonthly = selector.querySelector('[data-wizard-example-monthly]');
    this.exampleMonthly2 = selector.querySelector('[data-wizard-monthly]');

    if(this.initialData){

      this.activeLoan = this.initialData.find((item) => {
        return item.is_active === 1;
      });

      if(this.activeLoan){
        this.activeTerm = this.activeLoan.terms.find((term) => {
          return term.term == this.activeLoan.default_term;
        });
        this.activeAmount = this.activeLoan.default_amount;
      }

      setTimeout(() => {
        if(this.activeLoan && this.activeTerm && this.activeAmount){
          this.initDropdown(selector);
          this.changeLoanType();
  
          this.initTermsSlider();
          this.initAmountSlider();
  
          this.changeTermsSliderValue();
          this.changeAmountSliderValue();

          this.changeTermsInput();
          this.changeAmountInput();

          this.formSubmission();

          this.getResults();
        }
      }, 1);
    }
  }

  private formSubmission(){
    this.formDom.addEventListener('submit', (e: Event) => {
      e.preventDefault();
      this.submitForm();
    });
  }

  private changeTermsInput(){
    this.termsInput.addEventListener('blur', () => {
      this.termsSlider.set(this.termsInput.value);
      this.activeTerm = this.activeLoan.terms.find((term) => {
        return term.term == this.termsInput.value;
      });
      this.checkBoundaries();
    });
  }

  private changeAmountInput(){
    this.amountInput.addEventListener('blur', () => {
      this.amountSlider.set(this.amountInput.value);
      this.activeAmount = this.amountInput.value;
      this.checkBoundaries();
    });
  }

  private initTermsSlider(){
    this.termsSlider = noUiSlider.create(this.termsSliderDom, {
      step: this.termsRangeStep,
      start: this.activeLoan.default_term,
      range: {
        min: this.activeLoan.min_term,
        max: this.activeLoan.max_term
      },
      connect: false,
      tooltips: false,
    });
  }

  private updateTermsSlider(){
    this.termsSlider.updateOptions({
      start: this.activeLoan.default_term,
      range: {
        min: this.activeLoan.min_term,
        max: this.activeLoan.max_term,
      },
    });

    this.termsMinDom.innerText = this.activeLoan.min_term;
    this.termsMaxDom.innerText = this.activeLoan.max_term;
    this.termsInput.value = this.activeLoan.default_term;
  }

  private initAmountSlider(){
    this.amountSlider = noUiSlider.create(this.amountSliderDom, {
      step: this.amountRangeStep,
      start: this.activeLoan.default_amount,
      range: {
        min: this.activeLoan.min_amount,
        max: this.activeLoan.max_amount,
      },
      connect: false,
      tooltips: false,
    });
  }

  private updateAmountSlider(){
    this.amountSlider.updateOptions({
      start: this.activeLoan.default_amount,
      range: {
        min: this.activeLoan.min_amount,
        max: this.activeLoan.max_amount,
      },
    });

    this.amountMinDom.innerText = this.activeLoan.min_amount;
    this.amountMaxDom.innerText = this.activeLoan.max_amount;
    this.amountInput.value = this.activeLoan.default_amount;
  }

  private initDropdown(selector: HTMLElement) {
    new choicesJs(this.dropdownDom, {
      searchEnabled: false,
      removeItemButton: false,
      shouldSort: false,
      itemSelectText: '',
    });
    selector.querySelectorAll('.choices').forEach((choice) => {
      choice.classList.add('is-blue');
    });
  }

  private changeLoanType() {
    this.dropdownDom.addEventListener('change', (e: CustomEvent) => {
      this.setActiveLoan(parseInt(e.detail.value, 10));
      this.updateTermsSlider();
      this.updateAmountSlider();
      this.checkBoundaries();
    });
  }

  private setActiveLoan(loan_id: number){
    this.activeLoan = this.initialData.find((item) => {
      return item.loan_id === loan_id;
    });

    this.activeTerm = this.activeLoan.terms.find((term) => {
      return term.term == this.activeLoan.default_term;
    });
    this.activeAmount = this.activeLoan.default_amount;
  }

  private changeTermsSliderValue() {
    this.termsSlider.on('change', (value: any) => {
        this.activeTerm = this.activeLoan.terms.find((term) => {
          return term.term == parseInt(value[0], 10);
        });

        this.checkBoundaries();
    });

    this.termsSlider.on('update', (value: any) => {
      this.termsInput.value = parseInt(value[0], 10);
    });
  }

  private changeAmountSliderValue() {
    this.amountSlider.on('change', (value: any) => {
      const intValue: number = parseInt(value[0], 10);
      if(intValue == this.activeTerm.min_amount){
        this.activeAmount = this.activeTerm.min_amount;
      }
      else if(intValue == this.activeTerm.max_amount){
        this.activeAmount = this.activeTerm.max_amount;
      }
      else {
        this.activeAmount = Math.ceil(intValue / 100) * 100;
      }

      this.checkBoundaries();
    });

    this.amountSlider.on('update', (value: any) => {
      const intValue: number = parseInt(value[0], 10);
      if(intValue == this.activeTerm.min_amount){
        this.amountInput.value = this.activeTerm.min_amount;
      }
      else if(intValue == this.activeTerm.max_amount){
        this.amountInput.value = this.activeTerm.max_amount;
      }
      else {
        this.amountInput.value = Math.ceil(intValue / 100) * 100;
      }
    });
  }

  private checkBoundaries(){
    let showExplain = false;
    if(this.activeAmount < this.activeTerm.min_amount){
      this.amountSlider.set(this.activeTerm.min_amount);
      this.activeAmount = this.amountInput.value;
      showExplain = true;
    }

    if(this.activeAmount > this.activeTerm.max_amount){
      this.amountSlider.set(this.activeTerm.max_amount);
      this.activeAmount = this.amountInput.value;
      showExplain = true;
    }

    if(showExplain){
      this.explainTermDom.innerText = this.activeTerm.term;
      this.explainMinAmountDom.innerText = this.activeTerm.min_amount;
      this.explainMaxAmountDom.innerText = this.activeTerm.max_amount;
      this.explainDom.classList.remove('form__row--hidden');
    }
    else {
      this.explainDom.classList.add('form__row--hidden');
    }

    this.getResults();
  }

  private getResults(){
    const endpoint = this.formDom.getAttribute('action');
    const params = urijs(endpoint).query({
      type: this.activeLoan.loan_id,
      term: this.activeTerm.term,
      amount: this.activeAmount,
      redirect: 0,
    });

    fetch(params as any, {
      method: 'GET',
    })
    .then((r: Response) => r.text())
    .then((response: any) => {
      const responseData = JSON.parse(response);

      this.exampleAmount.innerText = responseData.total_amount;
      this.exampleTerm.innerText = responseData.term;

      this.exampleYearly.innerText = responseData.intr_yearly;
      this.exampleFee.innerText = responseData.tariff_admin_fee;
      this.exampleMonthlyFee.innerText = responseData.tariff_fee_monthly_perc;
      this.exampleBvkkmn.innerText = responseData.apr;
      this.exampleTotal.innerText = responseData.installment_total_amount;
      this.exampleMonthly.innerText = responseData.installment_avg_amount;
      this.exampleMonthly2.innerText = responseData.installment_avg_amount;

      dataLayerPush({
        calculatorType: 'double-slide',
        calculatorName: this.dropdownDom.innerText,
        calculatorAmount: this.activeAmount,
        calculatorDuration: this.activeTerm.term,
        calculatorPayment: responseData.installment_avg_amount,
        event: 'calculatorInteraction',
      });

    });
  }

  private submitForm(){
    const endpoint = this.formDom.getAttribute('action');
    const params = urijs(endpoint).query({
      type: this.activeLoan.loan_id,
      term: this.activeTerm.term,
      amount: this.activeAmount,
      redirect: 1,
    });

    fetch(params as any, {
      method: 'GET',
    })
    .then((r: Response) => r.text())
    .then((response: any) => {
      if (response != 'error') {
        window.location.href = response;
      }
    });
  }
}
