import * as GoogleMapsLoader from 'google-maps';

export class Maps {
  $maps: NodeListOf<HTMLElement> = document.querySelectorAll('[data-map]');
  styles: Object[] = [
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 35,
        },
        {
          hue: '#f0f5f9',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ];

  constructor() {
    if (this.$maps.length) this.init();
  }

  private init(): void {
    const gLoader = GoogleMapsLoader;
    gLoader['KEY'] = 'AIzaSyCIqmRyWTM_nDdgcV1N6jdoTn4pWw6fEJo';
    gLoader['VERSION'] = '3.34';
    this.$maps.forEach(($map: HTMLElement) => {
      const lat: number = Number($map.dataset.mapLat);
      const lng: number = Number($map.dataset.mapLng);
      const zoom: number = Number($map.dataset.mapZoom);
      let map: google.maps.Map;
      gLoader.load((google) => {
        map = new google.maps.Map($map, {
          zoom,
          center: { lat, lng },
          styles: this.styles,
        });
        const marker = new google.maps.Marker({
          map,
          position: new google.maps.LatLng(lat, lng),
          animation: google.maps.Animation.DROP,
          icon: {
            path:
              'M40,20.0909a20,20,0,1,0-40,0C0,31.22,20,52,20,52S40,31.22,40,20.0909ZM10.6863,' +
              '19.697A9.3138,9.3138,0,1,1,20,29.053,9.3944,9.3944,0,0,1,10.6863,19.697Z',
            fillColor: '#113c6a',
            fillOpacity: 1,
            anchor: new google.maps.Point(0, 0),
            strokeWeight: 0,
            scale: 1,
          },
        });
      });
    });
  }

  private openHash(): void {
    const $mapTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-maps-trigger]');
    const headerH = (document.querySelector('[data-header]') as HTMLElement).offsetHeight;
    if ($mapTriggers.length > 0 && window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      $mapTriggers.forEach(($trigger: HTMLElement) => {
        if ($trigger.dataset.mapsTrigger === targetId) {
          $trigger.click();
          setTimeout(() => {
            // window.scrollTo(0, offset($trigger).top - headerH);
          }, 1);
        }
      });
    }
  }
}
