import * as latinize from 'latinize';

export class Faq {
  query: string = '';
  $faq: HTMLElement = document.querySelector('[data-faq]');
  $input: HTMLInputElement = document.querySelector('[data-faq-input]');
  $activeTabsContent: HTMLElement = document.querySelector('[data-tabs-content].is-active');
  $noResults: HTMLElement = document.querySelector('[data-faq-no-results]');
  $faqItems: NodeListOf<HTMLElement> = document.querySelectorAll('[data-toggler-item]');

  constructor() {
    if (this.$faq) this.init();
  }

  public init(): void {
    this.$faqItems.forEach(($item: any) => {
      $item['question'] = latinize($item.innerText)
        .toLowerCase()
        .trim();
    });
    this.$input.addEventListener('input', this.inputHandler());
  }

  public inputHandler(): EventListener {
    return () => {
      let found: number = 0;
      this.query = latinize(this.$input.value).toLowerCase();
      this.$faqItems.forEach(($item: any) => {
        const inString = new RegExp(this.query).test($item['question']);
        $item.classList[inString ? 'remove' : 'add']('is-hidden');
        found = inString ? found + 1 : found;
      });
      this.$noResults.classList[found > 0 ? 'remove' : 'add']('is-visible');
    };
  }
}
