declare function require(name: string);

var x = window.matchMedia("(max-width: 1023px)");

export default {
  init() {
    var Sticky = require("sticky-js");
    var sticky;

    function myFunction(x) {
      if (x.matches) {
        if(sticky){
          sticky.destroy();
        }
      } else {
        sticky = new Sticky(".sticky-img");
      }
    }

    myFunction(x); // Call listener function at run time
    x.addListener(myFunction); // Attach listener function on state changes
  },
};
