import * as noUiSlider from 'noUiSlider';

interface IObject {
  [key: string]: any;
}

interface IProfferType {
  type: string;
  min: number;
  max: number;
  default: number;
  backgroundImage: string;
}

export class Proffer {
  currentProffer: IProfferType;
  types: IObject = {};
  amount: number;
  euroSuffix: string = '\xa0\u20AC';
  $select: HTMLSelectElement = document.querySelector('[data-proffer-select]');
  $amount: HTMLInputElement = document.querySelector('[data-proffer-amount]');
  $range: any = document.querySelector('[data-proffer-range]');
  $rangeMin: any = document.querySelector('[data-proffer-range-min]');
  $rangeMax: any = document.querySelector('[data-proffer-range-max]');
  $rangeHandle: HTMLElement;
  $rangeHandleIcon: HTMLElement = document.createElement('i');
  $types: NodeListOf<HTMLElement> = document.querySelectorAll('[data-proffer-type]');
  rangeSlider: IObject;
  rangeSliderOptions: IObject;

  constructor() {
    if (this.$types.length > 0) this.setupTypes();
  }

  private setupTypes() {
    const currentType: string = this.$select.value;
    this.$types.forEach(($type: HTMLElement) => {
      const data: IObject = { ...$type.dataset };
      const name: string = data['profferType'];
      Object.keys(data).map(() => {
        return (this.types[name] = {
          type: name,
          min: parseInt(data['profferMin'], 10),
          max: parseInt(data['profferMax'], 10),
          default: parseInt(data['profferDefault'], 10),
        });
      });
      this.types[name].backgroundImage = $type.style.backgroundImage;

      if (name === currentType) {
        this.currentProffer = this.types[name];
        this.amount = parseInt(data['profferDefault'], 10);
        this.$amount.value = String(this.amount);
        this.$rangeMin.innerText = parseInt(data['profferMin'], 10) + this.euroSuffix;
        this.$rangeMax.innerText = parseInt(data['profferMax'], 10) + this.euroSuffix;
      }
    });

    const step = 100;

    const eur = this.euroSuffix;

    noUiSlider.create(this.$range, {
      step,
      start: [this.currentProffer.default],
      range: {
        min: this.currentProffer.min,
        max: this.currentProffer.max,
      },
      connect: false,
      tooltips: true,
      format: {
        to(val: number) {
          return Math.floor(val) + eur;
        },
        from(val: string) {
          return val.replace(eur, '');
        },
      },
    });
    this.$range.noUiSlider.on('change', (values: any) => {
      const val = values[0].replace(this.euroSuffix, '');
      this.amount = parseInt(val, 10);
      this.$amount.value = val;
    });

    this.$rangeHandle = this.$range.querySelector('.noUi-handle');

    this.$rangeHandleIcon.classList.add('noUi-handle-icon', 'noUi-handle-icon--proffer');

    this.$rangeHandleIcon.style.backgroundImage = this.currentProffer.backgroundImage;

    this.$rangeHandle.appendChild(this.$rangeHandleIcon);

    // this.$rangeHandle.addEventListener('keydown', (e: any) => {
    //   const value = Number(this.$range.noUiSlider.get().replace(this.euroSuffix, ''));

    //   if (e.which === 37 || e.which === 40) {
    //     e.preventDefault();
    //     this.$range.noUiSlider.set(value - step);
    //   } else if (e.which === 38 || e.which === 39) {
    //     e.preventDefault();
    //     this.$range.noUiSlider.set(value + step);
    //   }
    // });

    this.$select.addEventListener('change', (event: CustomEvent) => {
      const type = event.detail.value;
      this.currentProffer = this.types[type];
      this.$range.noUiSlider.updateOptions(
        {
          start: [this.currentProffer.default],
          range: {
            min: this.currentProffer.min,
            max: this.currentProffer.max,
          },
        },
        true,
      );

      this.$rangeHandleIcon.style.backgroundImage = this.currentProffer.backgroundImage;
      this.$rangeMin.innerText = this.currentProffer.min + this.euroSuffix;
      this.$rangeMax.innerText = this.currentProffer.max + this.euroSuffix;
    });
  }
}
